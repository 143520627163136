.divParent {
    background-color: rgb(155, 125, 68);
    height: 100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px black;
    cursor: pointer;
}

.divParent:hover {
    filter: brightness(0.8);
    transform: scale(1.2);
}
